<template>
	<div class="whiteBack pt-0">
    <!-- <label class="mb-2">Your Location</label> -->
    <div>Enter your home address, or just your city and state.</div>
    <form ref="form">
      <div class=" mt-5">

        <div v-if="userProfile.address && Object.keys(userProfile.address).length >= 1">
          <div v-if="!showAddressChange">
            <div>
              <div class="caption">{{userProfile.address.street_number}} {{userProfile.address.street}} <span v-if="userProfile.address.unit">#{{userProfile.address.unit}}</span><br />{{userProfile.address.city}}, {{userProfile.address.state}} {{userProfile.address.zip}}</div>
              <button class="btn btn__outlined btn__small ml-3 mt-3 mb-3" @click="showAddChange()">Edit</button>
            
              <div class="mt-3 flex align-center justify-center" v-if="userProfile && userProfile.address && userProfile.address.street_number">
                <label for="unit">Unit Number: (optional)</label>
                <div class="ml-3" style="width:100px;">
                <input type="text" v-model.trim="userProfile.address.unit" @change="updateProfile()" />
                </div>
              </div>
              
              
            </div>
            
          </div>

          <div v-if="showAddressChange">
            <!-- <div class="mb-2 mt-3 caption">Change your location below:</div> -->
            <label>
              <gmap-autocomplete
                class="v-input v-input__control outline-fix"
                 placeholder="Enter Your Address" @place_changed="setPlace">
              </gmap-autocomplete>
            </label>
            <button class="btn btn__outlined btn__small ml-3 mt-3 mb-3" @click="cancelAddChange()">cancel</button>
          </div>
        

        </div>

        <div v-else>
          <label>
            <gmap-autocomplete
              class="v-input v-input__control outline-fix"
              @place_changed="setPlace" placeholder="Enter Your Location">
            </gmap-autocomplete>
          </label>
        </div>
      </div>
    </form>
	</div>
</template>

<script>
import firebase from 'firebase/app';

export default {
  props: ['userProfile', 'h2'],
  data: () => ({
    valid: true,
    center: { lat: 45.508, lng: -73.587 },
    marker: {},
    address: {},
    place: '',
    showAddressChange: false,
  }),
  computed: {
    complete () {
      if 
      (this.userProfile.address && Object.keys(this.userProfile.address).length >= 1)
      {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    updateProfile(){
      let userProfile = this.userProfile
      this.$store.dispatch('updateUser', userProfile)
    },
    setPlace(place) {
      this.currentPlace = place
        if (this.currentPlace) {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          };
          this.marker = {position: marker}
          this.place = this.currentPlace
          this.userProfile.center = marker;
          this.currentPlace = null;
        }
        if (place.address_components) {
          this.performingRequest = true
          this.userProfile.address = {}
          setTimeout(() => {
              this.performingRequest = false
          }, 1000)
          console.log(this.userProfile.address)
          var components = place.address_components;
          for (var i = 0, component; component = components[i]; i++) {
          if (component.types[0] == 'street_number') {
              this.userProfile.address.street_number = component['long_name'];
          }
          if (component.types[0] == 'route') {
              this.userProfile.address.street = component['long_name'];
          }
          if (component.types[0] == 'locality') {
              this.userProfile.address.city = component['long_name'];
          }
          if (component.types[0] == 'administrative_area_level_1') {
              this.userProfile.address.state = component['short_name'];
          }
          if (component.types[0] == 'country') {
              this.userProfile.address.country = component['short_name'];
          }
          if (component.types[0] == 'postal_code') {
              this.userProfile.address.zip = component['short_name'];
          }
        }
      }

      this.updateProfile()
      this.place = ''
      this.showAddressChange = false
      this.$emit('clicked', this.valid)
    },

    

    showAddChange() {
      this.showAddressChange = true
    },
    cancelAddChange() {
      this.showAddressChange = false
    },
    showChange() {
      this.showEdit = true
    },
    cancelChange() {
      this.showEdit = false
    },
    // setPlace(place) {
    //   this.currentPlace = place
    //     if (this.currentPlace) {
    //       const marker = {
    //         lat: this.currentPlace.geometry.location.lat(),
    //         lng: this.currentPlace.geometry.location.lng()
    //       };
    //       this.marker = {position: marker}
    //       this.place = this.currentPlace
    //       this.userProfile.center = marker;
    //       this.currentPlace = null;
    //     }
    //     if (place.address_components) {
    //       this.performingRequest = true
    //       this.userProfile.address = {}
    //       setTimeout(() => {
    //           this.performingRequest = false
    //       }, 1000)
    //       console.log(this.userProfile.address)
    //       var components = place.address_components;
    //       for (var i = 0, component; component = components[i]; i++) {
    //       if (component.types[0] == 'street_number') {
    //           this.userProfile.address.street_number = component['long_name'];
    //       }
    //       if (component.types[0] == 'route') {
    //           this.userProfile.address.street = component['long_name'];
    //       }
    //       if (component.types[0] == 'locality') {
    //           this.userProfile.address.city = component['long_name'];
    //       }
    //       if (component.types[0] == 'administrative_area_level_1') {
    //           this.userProfile.address.state = component['short_name'];
    //       }
    //       if (component.types[0] == 'country') {
    //           this.userProfile.address.country = component['short_name'];
    //       }
    //       if (component.types[0] == 'postal_code') {
    //           this.userProfile.address.zip = component['short_name'];
    //       }
    //     }
    //   }
    //   this.updateProfile()
    //   this.$emit('clicked', this.valid)
    // },
  },
  mounted () {
    this.$nextTick(() => {
      this.$emit('clicked', this.valid)
    })
  },
  beforeDestroy () {
  	this.$store.dispatch('clearErrors')
  }
}
</script>