<template>
  <div class="login">
    <Loader v-if="(!userProfile || !userProfile.id) || performingRequest" />
    <div class="login__containerLogin text-center widthControl" style="padding:0;" v-if="userProfile && userProfile.id">
      <div class="shiftCard">
        
          <div v-if="step1" class="pt-4">
            <h3>Enter Your Location</h3>
            <ProfileAddress :userProfile="userProfile"  />
            <div class="pa-5 pt-3 flex justify-flex-end" v-if="userProfile && userProfile.center">
              <button class="btn btn__primary" @click="goToStep2()">Continue</button>
            </div>
          </div>
        
        
          <div v-if="step2" class="pt-3">
          <h3>Upload Your Resumé</h3>
          <ProfileRes :userProfile="userProfile"  />
            <div class="pa-5 flex justify-space-between">
              <button v-if="userProfile && !userProfile.picture" class="btn btn__outlined-primary" @click="goToStep3()">Skip For Now</button>
              <button v-if="userProfile && userProfile.picture" class="btn btn__primary" @click="goToStep3()">Continue</button>
              <!-- <button class="btn btn__outlined" @click="goToStep1()">Go Back<i class="fas fa-arrow-left ml-3"></i></button> -->
            </div>
          </div>
        
          <div v-if="step3" class="pt-3">
            <h3>Choose your Avatar</h3>
            <ProfileImage :userProfile="userProfile"  :paylink="true" :skipButton="true" />
              <div class="pa-5 flex justify-space-between ">
                <button v-if="userProfile && userProfile.photoUrl" class="btn btn__primary" @click="goToComplete()">Continue</button>
                <button v-if="userProfile && !userProfile.photoUrl" class="btn btn__outlined-primary" @click="goToComplete()">Skip For Now</button>
                <button class="btn btn__outlined" @click="goToStep2()">Go Back<i class="fas fa-arrow-left ml-3"></i></button>
              </div>
          </div>
          
      </div>
    </div>
  </div>
</template>

<script>
import ProfileAddress from '@/components/Profile/AddressForSignUp'
import ProfileSkills from '@/components/Profile/ProfileSkills'
import ProfileTagline from '@/components/Profile/ProfileTagline'
import ProfileRes from '@/components/Profile/ProfileRes'
import ProfileImage from '@/components/Profile/ProfileImage'
import Loader from '@/components/Loader'
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'authComplete',
  data: () => ({
    performingRequest: false,
    goComplete: false,
    step1: false,
    step2: false,
    step3: false,
  }),
  components: {
    ProfileAddress,
    ProfileRes,
    ProfileTagline,
    ProfileSkills,
    ProfileImage,
    Loader
  },
  // async mounted () {
  //   await this.$store.dispatch("fetchUserProfile")
  // },
  mounted() {
    this.goToStep1()
  },
  computed: {
  ...mapState(['currentUser', 'userProfile']),
    step1Calc() {
      if (this.userProfile && this.userProfile.id && !this.userProfile.center) {
        return this.goToStep1()
      } else {
        return null
      }
    },
    step2Calc() {
      if (this.userProfile && this.userProfile.id && this.userProfile.center) {
        return this.goToStep2()
      } else {
        return null
      }
    },
    // step2() {
    //   if ((this.userProfile && this.userProfile.id && !this.step1 && !this.userProfile.picture) || (this.goStep2) && !this.goStep5) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // step3() {
    //   if (this.userProfile && this.userProfile.id && !this.step1 && !this.step2 && !this.userProfile.tagline) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // step4() {
    //   if (this.userProfile && this.userProfile.id && !this.step1 && !this.step2 && !this.step3 && (!this.userProfile.skills || this.userProfile.skills.length < 4)) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // step5() {
    //   if ((this.userProfile && this.userProfile.id && !this.step1 && !this.step2 && !this.userProfile.photoUrl) || this.goComplete || this.goStep5) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    isComplete() {
      if (this.userProfile && this.userProfile.id && !this.step1 && !this.step2 && this.userProfile.photoUrl) {
        this.goToPayroll()
      } else {
        return null
      }
    }
  },
  methods: {
    goToStep1() {
      console.log('goToStep1')
      this.step1 = true
      this.step2 = false
      this.step3 = false
      this.goComplete = false
    },
    goToStep2() {
      console.log('goToStep2')
      this.step2 = true
      this.step1 = false
      this.step3 = false
      this.goComplete = false
    },
    goToStep3() {
      console.log('goToStep3')
      this.step2 = false
      this.step1 = false
      this.step3 = true
      this.goComplete = false
    },
    goToComplete() {
      console.log('goComplete')
      this.step2 = false
      this.step1 = false
      this.step3 = false
      this.goComplete = true
      this.$router.push('/account/welcome')
    },
    // goToWelcome() {
    //   this.$router.push('/account/payroll/home')
    // }
    goToPayroll() {
      this.performingRequest = true
      this.$router.push('/account/payroll/home')
    }
  },
  beforeDestroy () {
    this.performingRequest = false
  }
}
</script>